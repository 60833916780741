import React from "react"
import PropTypes from "prop-types"

import apaceLogo from "../../images/apace Logo_Main Logo.svg"
import {
  HiChevronDown,
  HiOutlineOfficeBuilding,
  HiOutlineUser,
  HiExternalLink,
} from "react-icons/hi"

const Nav2 = props => {
  const { isScrolled } = props

  return (
    <nav
      className={`navbar navbar-default ${
        isScrolled
          ? "scrolling_nav transition-all py-2"
          : "transition-all py-2 2xl:py-4 mb-[-110px]"
      }`}
    >
      <div className="container w-full">
        <div className=" hidden lg:flex justify-between w-full">
          <div>
            <a className="logo" href="/">
              <img
                src={apaceLogo}
                alt="apace"
                className="responsive_logo max-w-[400px]"
              />
            </a>
          </div>

          <div className="flex self-center" style={{ zIndex: 100 }}>
            <ul className=" flex gap-x-6 2xl:gap-x-10">
              <li className="parent flex self-center">
                <a
                  tabIndex="-1"
                  href="/"
                  className="nav_link_main flex gap-x-[2px]"
                >
                  <span>Merchants</span>
                  <span className="flex self-center mt-[2px]">
                    <HiChevronDown className=" text-lg 2xl:text-2xl" />
                  </span>
                </a>
                <ul className="child">
                  <p className="a-menu-header text-slate-400">MERCHANTS</p>
                  <li>
                    <a href="#partner-with-apace" className="nav_link mb-[5px]">
                      Partner with Apace
                    </a>
                  </li>
                  <li>
                    <a href="#merchant-portal" className="nav_link">
                      Transparency
                    </a>
                  </li>
                  <li>
                    <a href="#reimbursement" className="nav_link">
                      Return Reimbursement
                    </a>
                  </li>
                  <li>
                    <a href="#integration" className="nav_link">
                      Integrate
                    </a>
                  </li>

                  <p className="a-menu-header text-slate-400 border-top-1 pt-4 mt-2">
                    _______________________
                  </p>
                  <li>
                    <a
                      href="https://merchant.apacerefunds.com/auth/login"
                      className="menu-btn"
                      target="_blank"
                    >
                      <button>
                        <HiOutlineOfficeBuilding /> Merchant sign in
                      </button>
                    </a>
                  </li>
                  <li className="text-center">
                    <a
                      href="https://merchant.apacerefunds.com/auth/signup"
                      className="a-menu-btn-link mt-2 underline"
                      target="_blank"
                    >
                      Merchant sign up
                    </a>
                  </li>
                </ul>
              </li>
              {/* <li className="parent flex self-center">
                <a
                  tabIndex="-1"
                  href="/"
                  className="nav_link_main flex gap-x-[2px]"
                >
                  <span>Shoppers</span>
                  <span className="flex self-center mt-[2px]">
                    <HiChevronDown className=" text-lg 2xl:text-2xl" />
                  </span>
                </a>
                <ul className="child">
                  <p className="a-menu-header text-slate-400">SHOPPERS</p>
                  <li>
                    <a href="/customers" className="nav_link">
                      Your instant refund
                    </a>
                  </li>
                  <p className="a-menu-header text-slate-400 border-top-1 pt-4 mt-2">
                    _______________________
                  </p>
                  <li>
                    <a
                      href="/customers"
                      className="menu-btn border-top pt-4 mt-2"
                    >
                      <button>
                        <HiOutlineUser /> Shopper sign In
                      </button>
                    </a>
                  </li>
                </ul>
              </li> */}

              <li className="parent flex self-center">
                <a
                  tabIndex="-1"
                  href="/"
                  className="nav_link_main flex gap-x-[2px]"
                >
                  <span>Solutions</span>
                  <span className="flex self-center mt-[2px]">
                    <HiChevronDown className=" text-lg 2xl:text-2xl" />
                  </span>
                </a>
                <ul className="child">
                  <p className="a-menu-header text-slate-400">SOLUTIONS</p>
                  <li>
                    <a tabindex="-1" href="#early-refund" className="nav_link">
                      <strong>Early</strong>{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {" "}
                        Instant refund™
                      </span>
                    </a>
                  </li>
                  <li>
                    <a tabIndex="-1" href="#duly-refund" className="nav_link">
                      <strong>Duly</strong>{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {" "}
                        Instant refund™
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
              <li className="parent flex self-center">
                <a
                  tabIndex="-1"
                  href="/"
                  className="nav_link_main flex gap-x-[2px]"
                >
                  <span>Apace</span>
                  <span className="flex self-center mt-[2px]">
                    <HiChevronDown className=" text-lg 2xl:text-2xl" />
                  </span>
                </a>
                <ul className="child">
                  <p className="a-menu-header text-slate-400">APACE</p>
                  <li>
                    <a href="#roadmap" className="nav_link">
                      Our Roadmap
                    </a>
                  </li>
                  <li>
                    <a href="/legal/tos" className="nav_link">
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <a href="/legal/privacy" className="nav_link">
                      Privacy policy
                    </a>
                  </li>
                  <li>
                    <a href="/about" className="nav_link">
                      About us
                    </a>
                  </li>
                  <li>
                    <a href="#contact" className="nav_link">
                      Let's chat
                    </a>
                  </li>
                </ul>
              </li>
              <li className="parent flex self-center">
                <a
                  href="#marketplace"
                  className="nav_link_main flex gap-x-[2px]"
                >
                  <span>Marketplace</span>
                </a>
              </li>
              <li className="parent flex self-center">
                <a
                  tabIndex="-1"
                  href="https://share.hsforms.com/1bo94wr6BQqaYAL9fxl4b7gd0ydi"
                  target={"_blank"}
                  className="nav_link_main flex gap-x-[2px]"
                >
                  <span>Support</span>
                  <span className="flex self-center mt-[2px]">
                    <HiExternalLink className=" text-lg 2xl:text-2xl" />
                  </span>
                </a>
              </li>
              <li className="flex justify-center">
                <a
                  href="/shoppers"
                  className="for-shoppers bg-primary flex w-full justify-center items-center text-white text-base 2xl:text-lg font-medium text-center rounded-full transition-all duration-300 hover:shadow-xl min-w-[140px] min-h-[40px] 2xl:min-w-[180px] 2xl:min-h-[54px]"
                >
                  For Shoppers
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

Nav2.propTypes = {
  background: PropTypes.string,
  setCrossSlide: PropTypes.func,
}

export default Nav2
