import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
const Footer = () => {
  return (
    <div className="w-full bg-[#062A4F] md:px-10">
      <div className="container pt-[40px] md:pt-[66px] pb-[80px] md:pb-[126px]">
        <div className="grid grid-cols-1 md:grid-cols-6 gap-10">
          <div className="md:col-span-5 order-last md:order-first">
            <h1 className=" text-lg md:text-2xl lg:text-3xl font-bold text-white mb-[50px]">
              Satisfied customers{" "}
              <span className="text-[#3D87F5]">are repeat customers.</span>
            </h1>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-10">
              <div className="flex flex-col">
                <h1 className="text-xs font-medium text-white mb-[12px]">
                  MERCHANTS
                </h1>
                <Link to="#partner-with-apace">
                  <span className="footer_link">Partner with Apace</span>
                </Link>
                <Link to="#merchant-portal">
                  <span className="footer_link">Transparency</span>
                </Link>
                <Link to="#reimbursement">
                  <span className="footer_link">Return Reimbursement</span>
                </Link>
                <Link to="#integration">
                  <span className="footer_link">Integrate</span>
                </Link>
                <Link to="https://merchant.apacerefunds.com/auth/login">
                  <span className="footer_link">Merchant sign in</span>
                </Link>
                <Link to="https://merchant.apacerefunds.com/auth/signup">
                  <span className="footer_link">Merchant sign up</span>
                </Link>
              </div>
              {/* <div className="flex flex-col">
                <h1 className="text-xs font-medium text-white mb-[12px]">
                  SHOPPERS
                </h1>

                <Link to="/shoppers">
                  <span className="footer_link">Your instant refund</span>
                </Link>
                <Link target={"_blank"} to="https://merchant.apacerefunds.com">
                  <span className="footer_link">Shopper sign In</span>
                </Link>
              </div> */}
              <div className="flex flex-col">
                <h1 className="text-xs font-medium text-white mb-[12px]">
                  SOLUTIONS
                </h1>

                <Link to="#early-refund">
                  <span className="footer_link">
                    <strong>Early</strong>{" "}
                    <span style={{ fontWeight: "lighter" }}>
                      {" "}
                      Instant refund™
                    </span>
                  </span>
                </Link>
                <Link to="#duly-refund">
                  <span className="footer_link">
                    <strong>Duly</strong>{" "}
                    <span style={{ fontWeight: "lighter" }}>
                      {" "}
                      Instant refund™
                    </span>
                  </span>
                </Link>
              </div>
              <div className="flex flex-col">
                <h1 className="text-xs font-medium text-white mb-[12px]">
                  APACE
                </h1>

                <Link to="roadmap">
                  <span className="footer_link">Our Roadmap</span>
                </Link>
                <Link to="/legal/tos">
                  <span className="footer_link">Terms of services</span>
                </Link>
                <Link to="/legal/privacy">
                  <span className="footer_link">Privacy policy</span>
                </Link>
                <Link to="/about">
                  <span className="footer_link">About us</span>
                </Link>
                <Link to="#contact">
                  <span className="footer_link">Let's chat</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="order-first md:order-last">
            <div className="mb-10">
              <StaticImage src="../../images/footerLogo.svg" alt="Apace" />
            </div>
            <div className="mb-[30px]">
              <h1 className="text-base text-white italic font-medium uppercase">
                CALL US
              </h1>
              <h3 className="text-lg text-white leading-[24px] font-medium">
                1-888-718-1004
              </h3>
            </div>

            <div>
              <h1 className="text-base text-white italic font-medium uppercase">
                {" "}
                EMAIL US
              </h1>
              <h3 className="text-lg text-white leading-[24px] font-medium">
                info@apacerefunds.com
              </h3>
            </div>
            <div className="mt-[30px]">
              <a
                href="https://www.producthunt.com/posts/apace-refunds?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-apace-refunds"
                target="_blank"
              >
                <img
                  src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=370080&theme=light"
                  alt="Apace Refunds - Get your instant refund in real-time | Product Hunt"
                  style={{ width: 250, height: 54 }}
                  width="250"
                  height="54"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
