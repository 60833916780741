import React from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import MobileMenu from "./MobileMenu"
import Banner from "../Views/Home/Banner"

const Layout = ({ children, isScrolled }) => {
  return (
    <div>
      <Banner />
      <div>
        <Navbar isScrolled={isScrolled} />
      </div>
      <div className="block lg:hidden">
        <MobileMenu />
      </div>
      {children}
      <Footer />
    </div>
  )
}

export default Layout
