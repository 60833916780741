import React, { useEffect, useState } from "react"
import ConfettiExplosion from "react-confetti-explosion"
import axios from "axios"

const MERCHNATS_URL = "https://api.jsonbin.io/v3/b/637fc69465b57a31e6c281c6"

const Banner = () => {
  const [newMerchants, setNewMerchants] = useState([])
  const [showConf, setShowConf] = useState(true)

  const getNewMerchants = async () => {
    let merchants = await axios.get(MERCHNATS_URL)
    if (merchants?.data?.record) {
      setNewMerchants(merchants?.data?.record?.filter(m => m.show))
      setTimeout(() => setShowConf(false), 2000)
    }
  }

  useEffect(() => {
    getNewMerchants()
  }, [])

  return newMerchants.length ? (
    <div className="w-full bg-[#004990] p-2">
      <div className="w-full banner" id="banner">
        <h1 className="text-[white] mr-[20px]">
          Welcome to our newly joined merchants
        </h1>
        <div className="banner-images">
          {showConf ? (
            <ConfettiExplosion
              height={500}
              particleCount={60}
              colors={["white", "#0d83b9"]}
            />
          ) : null}
          {newMerchants.map((m, i) => (
            <span key={i} className="mx-3">
              <a href={`${m.merchantSite}?ref=apace`} target="_blank">
                <img
                  className={m.makeWhite ? "white-image" : ""}
                  src={m.merchantLogo}
                  alt={m.merchantName}
                  title={m.merchantName}
                />
              </a>
            </span>
          ))}
        </div>
      </div>
      {/* <hr style={{ borderColor: "#4f68a1" }} />
      <h1 className="text-[white] mr-[20px] pt-2 text-center">
        Chat with John, our amazing AI bot that's so popular these days, and get
        the most out of your online merchant business with Apace!{" "}
        <a
          className="font-bold text-[#92c7fc]"
          href="/#:~:text=Let%27s%20talk%20about%20Apace%20Refunds%20%2D%20Any%20questions%3F"
        >
          Here
        </a>
      </h1> */}
    </div>
  ) : null
}

export default Banner
